import type { Ref } from 'vue';

export const PASSWORD_FORM_RULES = [
    ...truthyRule('Password is required'),
    (v: string) => v.length > 10 || 'Password must be at least 10 characters',
    (v: string) =>
        /(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(v)
        || 'Password must contain at least one uppercase letter, one lowercase letter, and one number',
];

export const EMAIL_FORM_RULES = [
    ...truthyRule('E-mail is required'),
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
];

export const LATITUDE_RULES = [
    ...numberRule('Latitude must be a number'),
    (v: string) => Number(v) >= -90 && Number(v) <= 90 || 'Latitude must be between -90 and 90',
    (v: string) => Number(v) >= -180 && Number(v) <= 180 || 'Longitude must be between -180 and 180',
];

export const LONGITUDE_RULES = [
    ...numberRule('Longitude must be a number'),
    (v: string) => Number(v) >= -180 && Number(v) <= 180 || 'Longitude must be between -180 and 180',
];

export function truthyRule<TVal = string>(message: string) {
    return [
        (v: TVal) => !!v || message,
    ];
}

export function numberRule(message: string) {
    return [
        (v: string) => !isNaN(Number(v)) || message,
    ];
}

export function notNegativeNumberRule(message: string) {
    return [
        ...numberRule(message),
        (v: string) => Number(v) >= 0 || message,
    ];
}

export function positiveNumberRule(message: string) {
    return [
        ...numberRule(message),
        (v: string) => Number(v) > 0 || message,
    ];
}

export function confirmPasswordRule(password: Ref<string>) {
    return [
        ...PASSWORD_FORM_RULES,
        (v: string) => (v && v === password.value) || 'Passwords must match',
    ];
}
