/* eslint-disable import/namespace */
import { SERVER_BASE_URL } from '@/enviornment';
import type { SimpleApiResult } from '@/shared/types';
import * as Sentry from '@sentry/vue';
import { toast } from 'vuetify-sonner';
import type { Endpoint, EndpointLiterals, EndpointResponse } from '../endpoints';
import { ENDPOINTS, INVALIDATIONS } from '../endpoints';
import { getMessagesFromError } from '../hooks/useApiResponseErrors';
import { isErrorsResponse } from '../models';
import type { PrimitiveEndpointDefinition } from '../types';
import { authedFetch, unAuthedFetch } from './authedFetch';
import type { ApiCallRequestArgs, PathParamsObject } from './types';

const ENDPOINT_CACHE: Record<string, PrimitiveEndpointDefinition> = {};

export function getEndpointDefinition<TEndpoint extends Endpoint>(endpoint: TEndpoint): PrimitiveEndpointDefinition {
    if (!ENDPOINT_CACHE[endpoint]) {
        const [feature, featureEndpoint] = endpoint.split('/') as [
            keyof typeof ENDPOINTS,
            keyof EndpointLiterals[keyof EndpointLiterals],
        ];

        const endpointDef = ENDPOINTS[feature][featureEndpoint] as PrimitiveEndpointDefinition;

        ENDPOINT_CACHE[endpoint] = {
            ...endpointDef,
            invalidates: [...endpointDef.invalidates ?? [], ...INVALIDATIONS[endpoint] ?? []],
        };
    }

    return ENDPOINT_CACHE[endpoint];
}

/** Will inject the provided params into the path */
function injectPathParams<T extends string>(path: T, params: PathParamsObject<T>): string {
    return path.replace(/{(.*?)}/g, (match, p1) => {
        return params[p1 as keyof typeof params] as string;
    });
}

function handleToastError(err: unknown) {
    if (!isErrorsResponse(err)) {
        toastError(err);
        console.error('Error response has unexpected formatting', err);

        if (err instanceof Error) {
            throw err;
        }

        throw new Error('Error response has unexpected formatting');
    }

    if (err.type === 'Fatal') {
        console.error('Fatal error', err);
        toastError(err);
    }
}

/** Necessary to resolve args array since it can be variable length */
function resolveArgs<TEndpoint extends Endpoint>(
    ...args: ApiCallRequestArgs<TEndpoint>
) {
    const [endpoint, requestOrParams, params] = args;
    const { method } = getEndpointDefinition(endpoint);

    if (method == 'GET') {
        if (requestOrParams && params) {
            throw new Error('Cannot provide both request and params for a GET request');
        }

        return {
            endpoint,
            request: undefined,
            params: requestOrParams,
        };
    }

    return {
        endpoint,
        request: requestOrParams,
        params,
    };
}

function toastError(err: unknown) {
    const messages = getMessagesFromError(err as never);

    toast.error(messages[0], {
        action: {
            label: 'Close',
        },
    });
}

/** Used specifically for react query */
export async function callApiUnsafe<TEndpoint extends Endpoint>(...args: ApiCallRequestArgs<TEndpoint>) {
    return await callApiUnsafeInternal<TEndpoint>(true, ...args);
}

/** Used specifically for react query */
export async function callApiWithoutAuthUnsafe<TEndpoint extends Endpoint>(...args: ApiCallRequestArgs<TEndpoint>) {
    return await callApiUnsafeInternal<TEndpoint>(false, ...args);
}

/** Used specifically for react query */
async function callApiUnsafeInternal<TEndpoint extends Endpoint>(
    authed: boolean,
    ...args: ApiCallRequestArgs<TEndpoint>
) {
    const { endpoint, request, params } = resolveArgs(...args);
    const { method, path } = getEndpointDefinition(endpoint);

    // Ensure that the path params are injected if they exist
    const injectedPath = params ? injectPathParams(path, params as PathParamsObject<typeof path>) : path;

    // GET requests don't have a body
    const body = method == 'GET' ? undefined : JSON.stringify(request);

    // We don't want to log auth requests
    if (endpoint.startsWith('auth')) {
        console.log('Calling API', { endpoint, request, params, method, path, injectedPath });
    } else {
        console.log('Calling API', { endpoint, request, params, method, path, injectedPath, body });
    }

    const fetcher = authed ? authedFetch : unAuthedFetch;

    try {
        const result = await fetcher<EndpointResponse[TEndpoint]>(`${SERVER_BASE_URL}${injectedPath}`, {
            method: method,
            body: body,
        });

        if (!endpoint.startsWith('auth')) {
            console.log('API call result', {
                endpoint,
                result,
                injectedPath,
            });
        }

        return result;
    } catch (err) {
        handleToastError(err);
        reportErrorToSentry(err as Error, { endpoint, path: injectedPath, method });

        throw err;
    }
}

export async function callApi<TEndpoint extends Endpoint>(
    ...args: ApiCallRequestArgs<TEndpoint>
): Promise<SimpleApiResult<EndpointResponse[TEndpoint]>> {
    return await callApiInternal<TEndpoint>(true, ...args);
}

export async function callApiWithoutAuth<TEndpoint extends Endpoint>(
    ...args: ApiCallRequestArgs<TEndpoint>
): Promise<SimpleApiResult<EndpointResponse[TEndpoint]>> {
    return await callApiInternal<TEndpoint>(false, ...args);
}

async function callApiInternal<TEndpoint extends Endpoint>(
    authed: boolean,
    ...args: ApiCallRequestArgs<TEndpoint>
): Promise<SimpleApiResult<EndpointResponse[TEndpoint]>> {
    const { endpoint, request, params } = resolveArgs(...args);
    const { method, path } = getEndpointDefinition(endpoint);

    // Ensure that the path params are injected if they exist
    const injectedPath = params ? injectPathParams(path, params as PathParamsObject<typeof path>) : path;

    // GET requests don't have a body
    const body = method == 'GET' ? undefined : JSON.stringify(request);

    console.log('Calling API', { endpoint, request, params, method, path, body });

    try {
        const fetcher = authed ? authedFetch : unAuthedFetch;
        const result = await fetcher<EndpointResponse[TEndpoint]>(`${SERVER_BASE_URL}${injectedPath}`, {
            method: method,
            body: body,
        });

        return {
            success: true,
            data: result as never, // Any cast to handle the case where TData is never, can't get the typing to play nice
        };
    } catch (err) {
        handleToastError(err);
        reportErrorToSentry(err as Error, { endpoint, path: injectedPath, method });

        if (!isErrorsResponse(err)) {
            throw new Error('Unknown error');
        }

        return {
            success: false,
            error: {
                ...err,
            },
        };
    }
}

type SentryErrorContext = {
    endpoint: string;
    path: string;
    method: string;
} & Record<string, unknown>;

function reportErrorToSentry(err: Error, extra: SentryErrorContext) {
    const messages = getMessagesFromError(err);
    const tags: Record<string, string | number | boolean> = {
        kind: 'api-error',
        endpoint: extra.endpoint,
        method: extra.method,
    };

    if (!err.message) {
        Object.assign(err, {
            message: messages[0],
        });
    }

    let level: 'error' | 'warning' = 'error';
    if (isErrorsResponse(err)) {
        extra = {
            ...extra,
            errors: err.errors,
            fieldErrors: err.fieldErrors,
            errType: err.type,
        };

        level = 'warning'; // We don't want to log validation errors as errors and spam our monitoring
    }

    Sentry.captureEvent({
        level: level,
        message: err.message,
        extra,
        tags,
    });
}
