import type { EndpointDefinitions } from '@/features/api';
import { API_BASE } from '@/shared/constants';
import type { CreateScreenRequest } from './screenApiRequestModels';
import type { ScreenResponse } from './screenApiResponseModels';

export type ScreenEndpointDefinitions = {
    GET_SCREENS: {
        request: never;
        response: ScreenResponse[];
        kind: 'query';
    };
    GET_SCREEN: { // NEW
        request: never;
        response: ScreenResponse;
        kind: 'query';
    };
    CREATE_SCREEN: { // NEW
        request: CreateScreenRequest;
        response: ScreenResponse;
        kind: 'command';
    };
    UPDATE_SCREEN: { // NEW
        request: CreateScreenRequest;
        response: ScreenResponse;
        kind: 'command';
    };
    DELETE_SCREEN: { // NEW
        request: never;
        response: never;
        kind: 'command';
    };
    DEACTIVATE_SCREEN: { // NEW
        request: string;
        response: never;
        kind: 'command';
    };
    ACTIVATE_SCREEN: { // NEW
        request: string;
        response: never;
        kind: 'command';
    };
    GET_SCREENS_BOUNDS: {
        request: never;
        response: [[number, number], [number, number]];
        kind: 'query';
    };
};

export const SCREEN_ENDPOINTS = {
    GET_SCREENS: {
        path: `${API_BASE}/screens`,
        method: 'GET',
        kind: 'query',
    },
    GET_SCREEN: {
        path: `${API_BASE}/screen/{screenId}`,
        method: 'GET',
        kind: 'query',
    },
    CREATE_SCREEN: {
        path: `${API_BASE}/screen`,
        method: 'POST',
        successMessage: 'Screen created',
        kind: 'command',
        invalidates: ['screens/'],
    },
    UPDATE_SCREEN: {
        path: `${API_BASE}/screen/{screenId}`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Screen updated',
        invalidates: ['screens/'],
    },
    DELETE_SCREEN: {
        path: `${API_BASE}/screen/{screenId}`,
        method: 'DELETE',
        kind: 'command',
        successMessage: 'Screen deleted',
        invalidates: ['screens/'],
    },
    DEACTIVATE_SCREEN: {
        path: `${API_BASE}/screen/{screenId}/deactivate`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Screen deactivated',
        invalidates: ['screens/'],
    },
    ACTIVATE_SCREEN: {
        path: `${API_BASE}/screen/{screenId}/activate`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Screen activated',
        invalidates: ['screens/'],
    },
    GET_SCREENS_BOUNDS: {
        path: `${API_BASE}/screens/bbox`,
        method: 'GET',
        kind: 'query',
    },
} as const satisfies EndpointDefinitions<ScreenEndpointDefinitions>;
